import React from 'react'
import Layout from "../../../components/layout";
import { GrSecure, GrCheckmark, GrGift } from 'react-icons/gr';
import { Helmet } from 'react-helmet';

function Hosting() {

  const sitename = "Bart Sallé, freelance webdeveloper";
  const pagetitle = "Hosting & Onderhoud - Bart Sallé, freelance webdeveloper";
  const description = "Ik onderhoud voor diverse klanten maandelijks hun (Wordpress) website. Ben je niet tevreden over je hostingpartij? Ook dit kan ik voor je uit handen nemen.";
  const url = "https://www.bartsalle.nl/diensten/hosting-en-onderhoud/";

  return (
    <Layout>
      <Helmet>
        <title>{pagetitle}</title>
        <link rel="canonical" href={url} />
        <meta name="description" content={description} />
        <meta property="og:locale" content="nl_NL" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={pagetitle} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={url} />
        <meta property="og:site_name" content={sitename} />
        <body className="overzichtdiensten" />
      </Helmet>
        <div className="container">
          <h1>Hosting & Onderhoud</h1>
          <h2>Wordpress onderhoudspakket</h2>
          <p>Een nieuwe website is leuk maar deze dient net, zoals bijvoorbeeld een auto, onderhouden te worden. Zo blijft de kans om gehackt te worden minimaal. Daarom onderhoud ik voor diverse klanten maandelijks hun Wordpress website of WooCommerce webshop.</p>
          <p>Zoek je een betrouwbare partij voor het onderhoud van jouw website of webshop? Ik zorg ervoor dat de website beveiligd is en alles up-to-date blijft (core, plugins en themes). Daarnaast zijn er een aantal voordelen als je het onderhoud aan mij toevertrouwt:</p>
          <p>
            <ul className='ul-with-icons'>
              <li><span><GrCheckmark size={15} /></span> Voor het updaten maak ik altijd zelf nog een volledige website backup. De laatste 5 backups (maanden) worden veilig bewaard zodat we in geval van nood altijd kunnen restoren (naast eventuele backups die de webhoster nog maakt).</li>
              <li><span><GrSecure size={15} /></span> Ik beveilig je WordPress website tegen aanvallen van buitenaf om eventuele hacks te voorkomen.</li>
              <li><span><GrGift size={15} /></span> Een aantal <strong>premium Wordpress plugins</strong> (SeoPress Pro, WPVivid Backup & Migration Pro) zijn bij het onderhoud inbegrepen.</li>
            </ul>
          </p>
          <p>Wordpress onderhoud hoeft niet duur te zijn (vanaf € 180,00 per jaar). Stuur een berichtje met een tijdelijke backend login en en je ontvangt snel een scherp prijsvoorstel. De prijs is altijd enigszins afhankelijk van de hoeveelheid plugins in de website en de functionaliteit die de website/webwinkel bevat.</p>
          <h2>Hosting</h2>
          <p>Ben je niet tevreden over je hostingpartij? Ook dit kan ik voor je uit handen nemen en kans is groot dat de jaarlijkse kosten tevens lager uit zullen vallen! Ik host al jaren websites voor mijn klanten op een supersnel platform met ultrasnelle SSD opslag en meerdere backups per dag.</p>
        </div>
    </Layout>
  )
}

export default Hosting